var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.dialogWidth,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" "+_vm._s(_vm.$t('app.general.actions'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.save'))+" ")])],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',[_c('v-list',{attrs:{"three-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.equipos.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.equipos.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.equipos.single'),"items":_vm.equipos,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.logotipo || _vm.logo}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(data.item.sucursal.nombre || '')}})],1)]}}],null,true),model:{value:(_vm.item.equipo_id),callback:function ($$v) {_vm.$set(_vm.item, "equipo_id", $$v)},expression:"item.equipo_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.jugadores.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.jugadores.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.jugadores.single'),"items":_vm.jugadores,"item-text":"persona.fullname","item-value":"id","error-messages":errors,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.persona.fotografia}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.persona.fullname)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(data.item.pivot.posicion.nombre || '')}})],1)]}}],null,true),model:{value:(_vm.item.jugador_id),callback:function ($$v) {_vm.$set(_vm.item, "jugador_id", $$v)},expression:"item.jugador_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.arbitros.name')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.arbitros.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.arbitros.name'),"items":_vm.jornada.arbitros,"item-text":"persona.fullname","item-value":"id","error-messages":errors,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.persona.fotografia}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.persona.fullname)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(data.item.descripcion)}})],1)]}}],null,true),model:{value:(_vm.item.arbitro_id),callback:function ($$v) {_vm.$set(_vm.item, "arbitro_id", $$v)},expression:"item.arbitro_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.eventos.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.eventos.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.eventos.single'),"items":_vm.eventos,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},model:{value:(_vm.item.evento_id),callback:function ($$v) {_vm.$set(_vm.item, "evento_id", $$v)},expression:"item.evento_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.action_minute')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.action_minute'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.action_minute'),"type":"number","error-messages":errors},model:{value:(_vm.item.minuto),callback:function ($$v) {_vm.$set(_vm.item, "minuto", $$v)},expression:"item.minuto"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.description')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.description'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"placeholder":_vm.$t('app.headers.description'),"error-messages":errors},model:{value:(_vm.item.descripcion),callback:function ($$v) {_vm.$set(_vm.item, "descripcion", $$v)},expression:"item.descripcion"}})]}}],null,true)})],1)],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }