<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.general.actions') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.equipos.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.equipos.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.equipo_id"
                        :placeholder="$t('app.equipos.single')"
                        :items="equipos"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      >
                        <template v-slot:item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.logotipo || logo">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.nombre" />
                            <v-list-item-subtitle v-text="data.item.sucursal.nombre || ''" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.jugadores.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.jugadores.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.jugador_id"
                        :placeholder="$t('app.jugadores.single')"
                        :items="jugadores"
                        item-text="persona.fullname"
                        item-value="id"
                        :error-messages="errors"
                        required
                      >
                        <template v-slot:item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.persona.fotografia">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.persona.fullname" />
                            <v-list-item-subtitle v-text="data.item.pivot.posicion.nombre || ''" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.arbitros.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.arbitros.name')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.arbitro_id"
                        :placeholder="$t('app.arbitros.name')"
                        :items="jornada.arbitros"
                        item-text="persona.fullname"
                        item-value="id"
                        :error-messages="errors"
                        required
                      >
                        <template v-slot:item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.persona.fotografia">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.persona.fullname" />
                            <v-list-item-subtitle v-text="data.item.descripcion" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.eventos.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.eventos.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.evento_id"
                        :placeholder="$t('app.eventos.single')"
                        :items="eventos"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.action_minute') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.action_minute')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.minuto"
                        :placeholder="$t('app.headers.action_minute')"
                        type="number"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.description') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.description')"
                      rules=""
                    >
                      <v-textarea
                        v-model="item.descripcion"
                        :placeholder="$t('app.headers.description')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'JornadasAddActions',
    data () {
      return {
        dialog: false,
        jornada: false,
        item: {
          jornada_id: false,
          equipo_id: false,
          jugador_id: false,
          arbitro_id: false,
          evento_id: false,
          descripcion: '',
          minuto: null,
        },
        equipos: [],
        eventos: [],
        timePickerModal: false,
      }
    },
    computed: {
      jugadores () {
        let jugadores = []
        const equipo = this.equipos.filter(item => item.id === this.item.equipo_id)
        if (equipo.length > 0) {
          jugadores = equipo[0].jugadores
        }
        return jugadores
      },
    },
    mounted () {
      EventBus.$on('jornadas-add-actions', async item => {
        this.jornada = item
        this.item.jornada_id = this.jornada.id
        this.equipos = [
          this.jornada.equipo_local,
          this.jornada.equipo_visitante,
        ]

        this.toggleLoader()
        this.eventos = await this.getEventos()
        this.toggleLoader()

        this.dialog = true
      })
    },
    methods: {
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()
            await this.$http.post(route('v1/action'), this.item)
              .then(async response => {
                if (response.body.code === 200) {
                  EventBus.$emit('reload-journey')

                  this.item = {
                    jornada_id: false,
                    equipo_id: false,
                    jugador_id: false,
                    arbitro_id: false,
                    evento_id: false,
                    descripcion: '',
                    minuto: null,
                  }

                  requestAnimationFrame(() => {
                    this.$refs.observer.reset()
                  })

                  this.dialog = false
                } else {
                  this.processError(response)
                }
              }, error => this.processError(error))

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
